$fsi: 24px;
$color-text: #CAD1D6;

body{
  margin: 0;
	background: linear-gradient(#7293A6 26%, #548295 69%, #3C6B7E 100%);
	font-family: "Roboto", sans-serif;
	font-size: $fsi;
	color: $color-text;
	//box-sizing: border-box;
} 

#drawing{
	width: 100vw;
	height: 100vh;
}
svg{
	width: 100vw;
	height: 95vh;
	margin-top: 5vh;
	svg{
		display: none;
	}
}

.input{
	position: absolute;
	top: 3vw;
	left: 4vw;
	.label{
		display: inline-block;
	}
	input#float-value{
		box-sizing: border-box;
		padding-right: 30px;
		width: 150px;
		color: $color-text;
		font-size: $fsi;
		background: transparent;
		border: none;
		border-bottom: 1px solid rgba($color-text,0.4);
		outline: none;
		&:focus{
			border-bottom: 1px solid rgba($color-text,0.9);
		}
		&::placeholder {
			color: $color-text;
			opacity: 0.5;
		}
	}
}

@mixin image-button{
	text-indent: -9999px;
	display: block;
	background-repeat: no-repeat;
	background-size: contain;
}

.park{
	@include image-button;
	background-image: url('./park.svg');
	width: 4vw;
	height: 3vw;
	min-width: 40px;
	min-height: 30px;
	position: absolute;
	right: 4vw;
	bottom: 3vw;
	opacity: 0.8;
	&:hover{
		opacity: 0.6;
	}
}

#button_rnd{
	@include image-button;
	background-image: url('./random.svg');
	position: absolute;
	right: -10px;
	top: -2px;
	width: 30px;
	height: 30px;
}
